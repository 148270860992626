import React from 'react';
import styled from '@emotion/styled';

const BlueSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-image: url('.//back1.jpg');
  background-size: cover;
  text-align: center;
`;

const BlueDivider = styled.div`
  width: 20%; /* Set the width of the divider */
  height: 2px; /* Set the height of the divider */
  background-color: #fff; /* Color of the divider */
  margin: 10px 0; /* Add margin to top and bottom */
`;

function BlueSection() {
  return (
    <BlueSectionWrapper>
      <h1 style={{ color: 'white' }}>Airport Industry Recovery</h1>
      <BlueDivider />
      <p style={{ color: 'white', fontSize: '18px' }}>
        Ensuring the health, safety, and security of the travelling public and airport workers is always an airport’s top priority. As such, North American airports responded quickly to help contain the spread of COVID-19. Airports continue to take aggressive steps to protect everyone traveling through their facilities and their employees. ACI-NA has established the Airport Industry Recovery Advisory Panel to support and the broader industry in recovery.
      </p>
    </BlueSectionWrapper>
  );
}

export default BlueSection;
