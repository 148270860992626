import React from 'react';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

const FooterContainer = styled.div`
  background-image: url('.//back.jpg');
  background-size: cover;
  padding: 80px 0;
  text-align: center;
`;

const FooterText = styled(Typography)`
  color: blue;
  font-weight: bold;
  font-size: 14px; /* Adjust the font size as needed */
`;

const Divider = styled.div`
  width: 30%;
  height: 2px;
  background-color: blue;
  margin: 20px auto; /* Set left and right margins to auto */
`;

const FooterLink = styled(Link)`
  margin: 0 10px;
  font-weight: bold; /* Set font weight to bold */
  color: blue; /* Changed from #blue to blue */
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const SocialIconContainer = styled.div`
  margin-top: 20px;
`;

const SocialIcon = styled.a`
  margin: 0 20px;
  color: #000;
  font-size: 30px;
  text-decoration: none;
  &:hover {
    color: lightblue;
  }
`;

const EndSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.9);
  text-align: center;
`;

function Footer() {
  return (
    <>
      <FooterContainer>
        <FooterText variant="h7">ABOUT ACI-NA | CONTACT ACI-NA | PRIVACY | TERMS OF SERVICE</FooterText>
        <Divider />
        <Grid container justifyContent="center">
          <Grid item>
            <FooterLink to="/admin">LOGIN |</FooterLink>
            <FooterLink href="#">JOIN US |</FooterLink>
            <FooterLink href="#">CENTERLINES CONNECT |</FooterLink>
            <FooterLink href="#">PUBLICATIONS |</FooterLink>
            <FooterLink href="#">MEDIA CENTER</FooterLink>
          </Grid>
        </Grid>
        <SocialIconContainer>
          <SocialIcon href="#"><FaFacebook /></SocialIcon>
          <SocialIcon href="#"><FaTwitter /></SocialIcon>
          <SocialIcon href="#"><FaInstagram /></SocialIcon>
        </SocialIconContainer>
      </FooterContainer>

    </>
  );
}

export default Footer;
