import React, { useState } from 'react';
import AdminPanel from './AdminPanel'; // Import the AdminPanel component
import './Navbar.css'; // Import the custom styles
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem } from '@mui/material'; // Import Material-UI components
import { Menu as MenuIcon } from '@mui/icons-material'; // Import the MenuIcon
import { signOut } from 'firebase/auth'; // Import signOut function from Firebase auth
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { auth } from './FirebaseConfig';
import HomeDas from './HomeDas';
import ContactData from './ContactData';
import RefundData from './RefundData';

const Dashboard = () => {
  const [currentPage, setCurrentPage] = useState('home');
  const navigate = useNavigate(); // Use navigate from react-router-dom
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setAnchorEl(null); // Close the menu when a page is selected
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      // Sign out the user
      await signOut(auth);
      // Redirect to the admin login page
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {['Home', 'Application', 'Refund', 'Contact'].map((text, index) => (
              <MenuItem key={text} onClick={() => handlePageChange(text.toLowerCase())}>
                {text}
              </MenuItem>
            ))}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <main style={{ padding: '20px' }}>
        {currentPage === 'home' && (
          <HomeDas />
        )}
        {currentPage === 'application' && (
          <AdminPanelCard />
        )}
        {currentPage === 'refund' && (
          <RefundData />
        )}
        {currentPage === 'contact' && (
          <ContactData />
        )}
      </main>
    </div>
  );
};

const AdminPanelCard = () => {
  return (
    <div style={{ height: '90vh', overflowY: 'auto' }}>
      <AdminPanel />
    </div>
  );
};

export default Dashboard;
