// AuthenticatedAdminPanel.js
import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { Navigate, Route } from 'react-router-dom';
import AdminPanel from './AdminPanel';
import { auth } from './FirebaseConfig';
import Dashboard from './Dashboard';

const AuthenticatedAdminPanel = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  // Show a loading indicator while checking the authentication state
  if (loading) {
    return <div>Loading...</div>;
  }

  // If user is authenticated, render the AdminPanel, otherwise redirect to login
  return user ? <Dashboard /> : <Navigate to="/" />;
};

export default AuthenticatedAdminPanel;
