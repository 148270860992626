import React, { useState } from 'react';
import { getDocs, query, where, collection } from 'firebase/firestore';
import { firestore } from './FirebaseConfig'; // Ensure this path is correct
import { TextField, Button, Container, Grid, Typography, Box } from '@mui/material';
import Footer from './Footer';

const TrackApplication = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [status, setStatus] = useState('');

  const handleTrackClick = async () => {
    // Validate if the mobile number is provided
    if (!mobileNumber.trim()) {
      alert('Please enter Mobile Number');
      return;
    }

    // Create a query to search in the Firestore collection based on the mobile number
    const q = query(
      collection(firestore, 'loanApplications'), // Ensure 'loanApplications' is your collection name
      where('mobileNo', '==', mobileNumber.trim())
    );

    try {
      // Execute the query
      const querySnapshot = await getDocs(q);

      // Check if any documents match the query
      if (querySnapshot.empty) {
        setStatus('No matching records found');
      } else {
        // Loop through the matching documents (there should be only one in this case)
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          // Access the status property from the data
          const applicationStatus = data.status || 'Status not available';
          setStatus(applicationStatus);
        });
      }
    } catch (error) {
      console.error('Error querying Firestore:', error);
      setStatus('Error fetching data');
    }
  };

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5, mb: 5, p: 5, background: 'linear-gradient(to right, #3f1111, #e4b1b1)', borderRadius: 2 }}>
        <Box textAlign="center" mb={4}>
          <Typography variant="h4" color="white" component="div" gutterBottom>
            TRACK YOUR STATUS
          </Typography>
          <Box component="span" sx={{ display: 'block', width: '100px', height: '4px', backgroundColor: 'white', margin: '0 auto' }}></Box>
        </Box>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <TextField
              label="Mobile Number"
              variant="outlined"
              fullWidth
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              InputProps={{
                style: { color: 'white' }
              }}
              InputLabelProps={{
                style: { color: 'white' }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white'
                  },
                  '&:hover fieldset': {
                    borderColor: 'white'
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white'
                  }
                },
                '& .MuiInputBase-input': {
                  color: 'white'
                },
                '& .MuiInputLabel-root': {
                  color: 'white'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ height: 55, fontSize: 20 }}
              onClick={handleTrackClick}
            >
              Track
            </Button>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="white">
              {status}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default TrackApplication;
