import React, { useState, useEffect } from 'react';
import { getDocs, query, where, collection, updateDoc, doc } from 'firebase/firestore';
import { firestore } from './FirebaseConfig';
import { Container, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const questionsData = [
  { question: 'What is the capital of France?', options: ['London', 'Paris', 'Berlin', 'Rome'], correct: 'Paris' },
  { question: 'How many sides does a hexagon have?', options: ['5', '6', '7', '8'], correct: '6' },
  { question: 'Who wrote the play "Hamlet"?', options: ['William Shakespeare', 'Charles Dickens', 'Jane Austen', 'George Orwell'], correct: 'William Shakespeare' },
  { question: 'What is the chemical symbol for gold?', options: ['Au', 'Ag', 'Fe', 'Cu'], correct: 'Au' },
  { question: 'What is the square root of 64?', options: ['6', '7', '8', '9'], correct: '8' },
  { question: 'Which planet is known as the Red Planet?', options: ['Mars', 'Jupiter', 'Saturn', 'Venus'], correct: 'Mars' },
  { question: 'What is the longest river in the world?', options: ['Nile', 'Amazon', 'Mississippi', 'Yangtze'], correct: 'Nile' },
  { question: 'Who painted the Mona Lisa?', options: ['Leonardo da Vinci', 'Vincent van Gogh', 'Pablo Picasso', 'Michelangelo'], correct: 'Leonardo da Vinci' },
  { question: 'What is the chemical formula for water?', options: ['CO2', 'H2O', 'O2', 'NaCl'], correct: 'H2O' },
  { question: 'What is the capital of Japan?', options: ['Tokyo', 'Beijing', 'Seoul', 'Bangkok'], correct: 'Tokyo' },
  { question: 'What is the largest mammal?', options: ['Elephant', 'Blue whale', 'Giraffe', 'Gorilla'], correct: 'Blue whale' },
  { question: 'Who wrote "Pride and Prejudice"?', options: ['Jane Austen', 'Emily Brontë', 'Charlotte Brontë', 'Virginia Woolf'], correct: 'Jane Austen' },
  { question: 'What is the formula for the area of a circle?', options: ['πr^2', '2πr', 'πd', 'πr'], correct: 'πr^2' },
  { question: 'What is the capital of Brazil?', options: ['Buenos Aires', 'Rio de Janeiro', 'São Paulo', 'Brasília'], correct: 'Brasília' },
  { question: 'What is the chemical symbol for oxygen?', options: ['O', 'Ox', 'Oz', 'Oc'], correct: 'O' },
  { question: 'Who discovered penicillin?', options: ['Alexander Fleming', 'Albert Einstein', 'Isaac Newton', 'Thomas Edison'], correct: 'Alexander Fleming' },
  { question: 'What is the capital of Australia?', options: ['Sydney', 'Melbourne', 'Canberra', 'Perth'], correct: 'Canberra' },
  { question: 'What is the largest ocean in the world?', options: ['Atlantic', 'Indian', 'Pacific', 'Arctic'], correct: 'Pacific' },
  { question: 'What is the boiling point of water in Fahrenheit?', options: ['100°F', '212°F', '32°F', '0°F'], correct: '212°F' },
  { question: 'What is the chemical symbol for iron?', options: ['Ir', 'Fe', 'In', 'F'], correct: 'Fe' },
  { question: 'Who is known as the father of computers?', options: ['Charles Babbage', 'Alan Turing', 'Bill Gates', 'Steve Jobs'], correct: 'Charles Babbage' },
  { question: 'What is the formula for density?', options: ['Mass/Volume', 'Volume/Mass', 'Mass x Volume', 'Volume - Mass'], correct: 'Mass/Volume' },
  { question: 'What is the capital of Canada?', options: ['Toronto', 'Vancouver', 'Ottawa', 'Montreal'], correct: 'Ottawa' },
  { question: 'What is the chemical symbol for sodium?', options: ['Na', 'S', 'So', 'Sa'], correct: 'Na' },
  { question: 'Who was the first person to walk on the moon?', options: ['Neil Armstrong', 'Buzz Aldrin', 'Yuri Gagarin', 'Alan Shepard'], correct: 'Neil Armstrong' },
  { question: 'What is the formula for the circumference of a circle?', options: ['2πr', 'πr^2', 'πd', 'πr'], correct: '2πr' },
  { question: 'Who wrote "The Great Gatsby"?', options: ['F. Scott Fitzgerald', 'Ernest Hemingway', 'John Steinbeck', 'Mark Twain'], correct: 'F. Scott Fitzgerald' },
  { question: 'What is the chemical formula for carbon dioxide?', options: ['CO', 'CO2', 'C2O', 'C2O3'], correct: 'CO2' },
  { question: 'What is the capital of China?', options: ['Shanghai', 'Beijing', 'Hong Kong', 'Taipei'], correct: 'Beijing' },
  { question: 'What is the hardest natural substance on Earth?', options: ['Steel', 'Diamond', 'Quartz', 'Ruby'], correct: 'Diamond' },
  { question: 'Who painted the "The Starry Night"?', options: ['Vincent van Gogh', 'Pablo Picasso', 'Leonardo da Vinci', 'Claude Monet'], correct: 'Vincent van Gogh' },
  { question: 'What is the chemical symbol for helium?', options: ['H', 'He', 'Ho', 'Hy'], correct: 'He' },
  { question: 'What is the formula for acceleration?', options: ['Velocity/Time', 'Time/Velocity', 'Force/Mass', 'Change in Velocity/Time'], correct: 'Change in Velocity/Time' },
  { question: 'What is the capital of South Africa?', options: ['Johannesburg', 'Pretoria', 'Cape Town', 'Durban'], correct: 'Pretoria' },
  { question: 'What is the chemical formula for ammonia?', options: ['NH', 'NH2', 'NH3', 'N2H3'], correct: 'NH3' },
  { question: 'Who wrote "To Kill a Mockingbird"?', options: ['Harper Lee', 'Truman Capote', 'J.D. Salinger', 'Mark Twain'], correct: 'Harper Lee' },
  { question: 'What is the freezing point of water in Celsius?', options: ['0°C', '100°C', '-273°C', '32°C'], correct: '0°C' },
  { question: 'What is the capital of Germany?', options: ['Berlin', 'Munich', 'Frankfurt', 'Hamburg'], correct: 'Berlin' },
  { question: 'What is the chemical symbol for potassium?', options: ['K', 'P', 'Po', 'Ka'], correct: 'K' },
  { question: 'Who discovered gravity?', options: ['Isaac Newton', 'Galileo Galilei', 'Albert Einstein', 'Nikola Tesla'], correct: 'Isaac Newton' },
  { question: 'What is the formula for work?', options: ['Force x Distance', 'Mass x Acceleration', 'Mass x Velocity', 'Force x Time'], correct: 'Force x Distance' },
  { question: 'What is the capital of Russia?', options: ['Moscow', 'St. Petersburg', 'Kiev', 'Vladivostok'], correct: 'Moscow' },
  { question: 'What is the chemical formula for table salt?', options: ['NaCl', 'HCl', 'KCl', 'MgCl2'], correct: 'NaCl' },
  { question: 'Who wrote "1984"?', options: ['George Orwell', 'Aldous Huxley', 'Ray Bradbury', 'H.G. Wells'], correct: 'George Orwell' },
  { question: 'What is the hardest natural substance on Earth?', options: ['Steel', 'Diamond', 'Quartz', 'Ruby'], correct: 'Diamond' },
  { question: 'Who painted "The Starry Night"?', options: ['Vincent van Gogh', 'Pablo Picasso', 'Leonardo da Vinci', 'Claude Monet'], correct: 'Vincent van Gogh' },
  { question: 'What is the chemical symbol for helium?', options: ['H', 'He', 'Ho', 'Hy'], correct: 'He' },
  { question: 'What is the formula for acceleration?', options: ['Velocity/Time', 'Time/Velocity', 'Force/Mass', 'Change in Velocity/Time'], correct: 'Change in Velocity/Time' },
  { question: 'What is the capital of South Africa?', options: ['Johannesburg', 'Pretoria', 'Cape Town', 'Durban'], correct: 'Pretoria' },
  { question: 'What is the chemical formula for ammonia?', options: ['NH', 'NH2', 'NH3', 'N2H3'], correct: 'NH3' },
  { question: 'Who wrote "To Kill a Mockingbird"?', options: ['Harper Lee', 'Truman Capote', 'J.D. Salinger', 'Mark Twain'], correct: 'Harper Lee' },
  { question: 'What is the freezing point of water in Celsius?', options: ['0°C', '100°C', '-273°C', '32°C'], correct: '0°C' },
  { question: 'What is the capital of Germany?', options: ['Berlin', 'Munich', 'Frankfurt', 'Hamburg'], correct: 'Berlin' },
  { question: 'What is the chemical symbol for potassium?', options: ['K', 'P', 'Po', 'Ka'], correct: 'K' },
];

const Questions = () => {
  const [tokenDialogOpen, setTokenDialogOpen] = useState(true);
  const [token, setToken] = useState('');
  const [overlayVisible, setOverlayVisible] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [tokenData, setTokenData] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [questionTimer, setQuestionTimer] = useState(10);
  const [quizStarted, setQuizStarted] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (quizStarted && currentQuestionIndex < questionsData.length) {
      setCurrentQuestion(questionsData[currentQuestionIndex]);
      setQuestionTimer(10);
    }
  }, [currentQuestionIndex, quizStarted]);

  useEffect(() => {
    if (quizStarted && questionTimer > 0) {
      const timer = setInterval(() => {
        setQuestionTimer((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (questionTimer === 0) {
      handleTokenExpire();
    }
  }, [questionTimer, quizStarted]);

  const handleTokenSubmit = async () => {
    try {
      if (!token.trim()) {
        setErrorMessage('Please enter a token.');
        return;
      }

      const q = query(collection(firestore, 'loanApplications'), where('token', '==', token.trim()));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const tokenDoc = querySnapshot.docs[0];
        const tokenData = tokenDoc.data();
        setTokenData(tokenData);

        if (!tokenData.expired) {
          await updateDoc(doc(firestore, 'loanApplications', tokenDoc.id), { expired: true });
          setTokenDialogOpen(false);
          setOverlayVisible(false);
          setErrorMessage('');
          setQuizStarted(true);
        } else {
          setErrorMessage('This token has already been used. Please try another token.');
        }
      } else {
        setErrorMessage('Invalid token. Please try again.');
      }
    } catch (error) {
      console.error('Error validating token: ', error);
      setErrorMessage('Error validating token. Please try again.');
    }
  };

  const handleTokenExpire = () => {
    setTokenDialogOpen(true);
    setToken('');
    setOverlayVisible(true);
    setQuizStarted(false);
    setCurrentQuestionIndex(0);
    setQuestionTimer(10);
  };

  const handleNextQuestion = (selectedOption) => {
    if (selectedOption === currentQuestion.correct) {
      setCorrectAnswers((prev) => prev + 1);
    } else {
      setIncorrectAnswers((prev) => prev + 1);
    }
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handleCloseDialog = () => {
    navigate('/');
  };

  return (
    <>
      <Container maxWidth="md">
        <Paper elevation={3} style={{ padding: '20px', marginTop: '50px', marginBottom: '50px', position: 'relative' }}>
          <Typography variant="h4" align="center">Questions</Typography>

          <Dialog open={tokenDialogOpen}>
            <DialogTitle>Enter Your Pass Key</DialogTitle>
            <DialogContent style={{ marginTop: '10px'}}>
              <TextField
                label="Pass Key"
                variant="outlined"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                fullWidth
              />
              {errorMessage && (
                <Typography color="error" variant="body2">
                  {errorMessage}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="secondary">Close</Button>
              <Button onClick={handleTokenSubmit} color="primary">Submit</Button>
            </DialogActions>
          </Dialog>

          {overlayVisible && (
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              bgcolor="rgba(0,0,0,0.5)"
              display="flex"
              justifyContent="center"
              alignItems="center"
              zIndex={1000}
            />
          )}

          {tokenData && quizStarted && (
            <Box textAlign="center" mt={4}>
              <Typography variant="h6">Question Timer: {questionTimer} seconds</Typography>
              <Typography variant="h6">Question {currentQuestionIndex + 1} of {questionsData.length}</Typography>
              {currentQuestion && currentQuestion.question && (
                <>
                  <Typography variant="h5" mt={2} mb={4}>{currentQuestion.question}</Typography>
                  {currentQuestion.options && currentQuestion.options.map((option, index) => (
                    <Button key={index} variant="contained" color="primary" style={{ margin: '5px' }} onClick={() => handleNextQuestion(option)}>
                      {option}
                    </Button>
                  ))}
                </>
              )}
              <Typography variant="h6" mt={4}>Correct Answers: {correctAnswers}</Typography>
              <Typography variant="h6">Incorrect Answers: {incorrectAnswers}</Typography>
            </Box>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default Questions;
