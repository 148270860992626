import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { firestore, auth } from './FirebaseConfig';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Paper,
} from '@mui/material';
import styled from '@emotion/styled';

const TableHeaderCell = styled(TableCell)`
  white-space: nowrap;
  background-color: black;
  color: white;
`;

const StripedTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9f9f9;
  }
`;

const NoWrapCell = styled(TableCell)`
  white-space: nowrap;
`;

const AdminPanel = () => {
  const [data, setData] = useState([]);
  const [editableRows, setEditableRows] = useState([]);
  const [editValues, setEditValues] = useState([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const snapshot = await getDocs(collection(firestore, 'loanApplications'));
      const fetchedData = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          timestamp: data.timestamp ? data.timestamp.toDate().toString() : '',
        };
      });
      setData(fetchedData);
      setEditableRows(Array(fetchedData.length).fill(false));
      setEditValues(fetchedData.map(item => ({ token: item.token, status: item.status })));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this entry?')) {
      try {
        const collectionRef = collection(firestore, 'loanApplications');
        const docRef = doc(collectionRef, id);
        await deleteDoc(docRef);
        fetchData();
      } catch (error) {
        console.error('Error deleting data:', error);
      }
    }
  };

  const handleUpdate = async (id, index) => {
    if (window.confirm('Are you sure you want to save changes?')) {
      try {
        const collectionRef = collection(firestore, 'loanApplications');
        const docRef = doc(collectionRef, id);

        await updateDoc(docRef, { token: editValues[index].token, status: editValues[index].status });

        setEditableRows((prevStatus) => {
          const newStatus = [...prevStatus];
          newStatus[index] = false;
          return newStatus;
        });

        fetchData();
      } catch (error) {
        console.error('Error updating data:', error);
      }
    }
  };

  const handleLogout = () => {
    if (window.confirm('Are you sure you want to log out?')) {
      signOut(auth)
        .then(() => {
          navigate('/admin-login');
        })
        .catch((error) => {
          console.error('Error signing out:', error);
        });
    }
  };

  return (
    <div className="container-fluid">
      <h2>Applications</h2>
      <TableContainer component={Paper} style={{ overflowX: 'auto', maxWidth: '100%' }}>
        <Table sx={{ minWidth: 650, tableLayout: 'auto' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableHeaderCell>Sno</TableHeaderCell>
              <TableHeaderCell>First Name</TableHeaderCell>
              <TableHeaderCell>Last Name</TableHeaderCell>
              <TableHeaderCell>DateOfBirth</TableHeaderCell>
              <TableHeaderCell>Mobile</TableHeaderCell>
              <TableHeaderCell>Email</TableHeaderCell>
              <TableHeaderCell>Address</TableHeaderCell>
              <TableHeaderCell>State</TableHeaderCell>
              <TableHeaderCell>City</TableHeaderCell>
              <TableHeaderCell>Pan</TableHeaderCell>
              <TableHeaderCell>Aadhar</TableHeaderCell>
              <TableHeaderCell>PasportNo</TableHeaderCell>
              <TableHeaderCell>Avation</TableHeaderCell>
              <TableHeaderCell>Education</TableHeaderCell>
              <TableHeaderCell>Job Post</TableHeaderCell>
              <TableHeaderCell>Token</TableHeaderCell>
              <TableHeaderCell>Timestamp</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
              <TableHeaderCell colSpan="2">Action Button</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <StripedTableRow key={item.id}>
                <NoWrapCell>{index + 1}</NoWrapCell>
                <NoWrapCell>{item.name}</NoWrapCell>
                <NoWrapCell>{item.lastName}</NoWrapCell>
                <NoWrapCell>{item.dob}</NoWrapCell>
                <NoWrapCell>{item.mobileNo}</NoWrapCell>
                <NoWrapCell>{item.email}</NoWrapCell>
                <NoWrapCell>{item.address}</NoWrapCell>
                <NoWrapCell>{item.state}</NoWrapCell>
                <NoWrapCell>{item.city}</NoWrapCell>
                <NoWrapCell>{item.panNo}</NoWrapCell>
                <NoWrapCell>{item.uidNo}</NoWrapCell>
                <NoWrapCell>{item.passportNo}</NoWrapCell>
                <NoWrapCell>{item.aviationCert}</NoWrapCell>
                <NoWrapCell>{item.education}</NoWrapCell>
                <NoWrapCell>{item.jobPost}</NoWrapCell>
                <NoWrapCell>
                  {editableRows[index] ? (
                    <TextField
                      value={editValues[index].token}
                      onChange={(e) => {
                        const newEditValues = [...editValues];
                        newEditValues[index].token = e.target.value;
                        setEditValues(newEditValues);
                      }}
                    />
                  ) : (
                    item.token
                  )}
                </NoWrapCell>
                <NoWrapCell>{item.timestamp}</NoWrapCell>
                <NoWrapCell>
                  {editableRows[index] ? (
                    <TextField
                      value={editValues[index].status}
                      onChange={(e) => {
                        const newEditValues = [...editValues];
                        newEditValues[index].status = e.target.value;
                        setEditValues(newEditValues);
                      }}
                    />
                  ) : (
                    item.status
                  )}
                </NoWrapCell>
                <NoWrapCell colSpan="2">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDelete(item.id)}
                  >
                    Delete
                  </Button>
                  {!editableRows[index] && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        const newEditableRows = [...editableRows];
                        newEditableRows[index] = true;
                        setEditableRows(newEditableRows);
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  {editableRows[index] && (
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => handleUpdate(item.id, index)}
                    >
                      Save
                    </Button>
                  )}
                </NoWrapCell>
              </StripedTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AdminPanel;
