import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { firestore } from './FirebaseConfig';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const RefundData = () => {
  const [refundData, setRefundData] = useState([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedRefundId, setSelectedRefundId] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'Refund'));
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data() });
        });
        setRefundData(data);
      } catch (error) {
        console.error('Error fetching refund data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run the effect only once when the component mounts

  const handleDelete = async (id) => {
    setConfirmDeleteOpen(false); // Close the confirmation dialog
    try {
      await deleteDoc(doc(firestore, 'Refund', id));
      setRefundData(refundData.filter((refund) => refund.id !== id));
    } catch (error) {
      console.error('Error deleting refund data:', error);
    }
  };

  const handleConfirmDeleteOpen = (id) => {
    setSelectedRefundId(id);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
  };

  return (
    <div style={{ overflowY: 'auto', maxHeight: '100vh' }}>
      <Typography variant="h5" gutterBottom>Refund Data</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sno.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Application Number</TableCell>
              <TableCell>Full Amount</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {refundData.map((refund, index) => (
              <TableRow key={refund.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{refund.name}</TableCell>
                <TableCell>{refund.email}</TableCell>
                <TableCell>{refund.mobile}</TableCell>
                <TableCell>{refund.applicationNo}</TableCell>
                <TableCell>{refund.amount}</TableCell>
                <TableCell>{refund.timestamp && refund.timestamp.toDate().toLocaleString()}</TableCell>
                <TableCell>
                  <Button variant="contained" color="error" onClick={() => handleConfirmDeleteOpen(refund.id)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={confirmDeleteOpen} onClose={handleConfirmDeleteClose}>
        <DialogTitle>Delete Refund Record</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this refund record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose}>Cancel</Button>
          <Button onClick={() => handleDelete(selectedRefundId)} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RefundData;
