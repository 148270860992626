import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import Footer from './Footer';

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      setLoading(true);
      setError('');

      // Basic email validation
      if (!email || !/^\S+@\S+\.\S+$/.test(email)) {
        throw new Error('Invalid email format');
      }

      // Basic password validation
      if (!password || password.length < 6) {
        throw new Error('Password should be at least 6 characters');
      }

      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
      console.log('Login successful');
      navigate('/dashboard');
    } catch (error) {
      console.error('Login failed:', error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Container maxWidth="sm" sx={{ mt: 5, mb: 5, p: 5,  }}>
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Admin Login
          </Typography>
          <form>
            <TextField
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
              margin="normal"
            />
            <TextField
              label="Password"
              type="password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
              margin="normal"
            />
            {error && <Typography variant="body2" color="error">{error}</Typography>}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleLogin}
              disabled={loading}
            >
              {loading ? 'Logging in...' : 'Login'}
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
    <Footer />
    </>
  );
};

export default AdminLogin;
