// FirebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { collection, addDoc, serverTimestamp, doc, deleteDoc, getDoc, updateDoc, query, where, getDocs } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyB2xWwDCQ0Ido3L56LNxOkiJp6tbtFulxQ",
  authDomain: "indigo-8f832.firebaseapp.com",
  projectId: "indigo-8f832",
  storageBucket: "indigo-8f832.appspot.com",
  messagingSenderId: "687741386940",
  appId: "1:687741386940:web:0097d695ea9ba2ed1e50b9",
  measurementId: "G-96WJVCGH5M"
};


const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

export { firestore, auth, collection, addDoc, serverTimestamp, doc, deleteDoc ,getDoc, updateDoc, query, where, getDocs };

