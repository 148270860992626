import React from 'react';
import styled from '@emotion/styled';
import { Container, Grid, Typography, Card, CardContent, Avatar, Box } from '@mui/material';
import { FaPlane, FaMoneyBillAlt, FaHandshake, FaGlobe } from 'react-icons/fa';
import BottomAbout from './BottomAbout';
import Footer from './Footer';



const Root = styled.div`
  position: relative;
  width: 100%;
  height: 35vh; /* Adjusted height to 50% of the viewport height */
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%; /* Full height of Root */
  object-fit: cover; /* Ensure the image covers the entire area */
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Match the height of the image */
  background-color: rgba(0, 0, 0, 0.6); /* Black transparent overlay */
  display: flex;
  justify-content: flex-start; /* Align content to the left */
  align-items: center;
  padding-left: 20px; /* Add padding to the left side */
`;

const Content = styled.div`
  color: #fff;
  text-align: left; /* Align text to the left */
  font-weight: bold; /* Make all text bold */
`;

const MainSection = styled.section`
  margin: 0; /* Remove margin between the image and the section */
  padding: 20px;
  background-color: #fff; /* Add background color to the section */
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const Title = styled.h1`
  font-size: 2rem;
  text-align: left ;
  margin-top: 0px; /* Add a margin to the top for spacing */
`;
const SubTitle = styled.h1`
  font-size: 1.5rem;
  text-align: left ;
  margin-top: 20px; /* Add a margin to the top for spacing */
`;

function About() {
  return (
    <>
      <Root>
        <Image src="/airo.png" alt="Background" />
        <Overlay>
          <Content>Home/About Us</Content>
        </Overlay>
      </Root>
      <MainSection>
        <Title>About Us</Title>
        <p>IndiGo is India’s largest and most preferred passenger airline and amongst the fastest growing airlines in the world.</p>
      <p>We have a simple philosophy: offer fares that are affordable, flights that are on time, and provide a courteous and hassle-free travel experience across our unparalleled network. We show that low cost does not mean low quality. With our fleet of over 360 aircraft, we operate well over 2,000 daily flights, connecting over 110+ destinations (of which 33 international), welcoming 100+ million customers on board last year. We have an industry leading on-time performance and one of the highest customer NPS in the Indian market. At IndiGo, we will continue to extend our scope, by spreading our wings internationally, developing from a domestic carrier to a global aviation leader.</p>
      <SubTitle>India by IndiGo</SubTitle>
      <p>Right from IndiGo’s start, it has been our mission to connect the vast and diverse India, supporting social cohesion, mobility and economic progress. With our unparalleled network we are giving wings to the nation, enabling air travel to all large, medium, and smaller cities across India. Our many first-time flyers are a true testimony to this, just as the economy and trade that can flourish as a result of all these connections. And so, our purpose is ‘Giving wings to the nation, by connecting people and aspirations’. This is also briefly captured as ‘India by IndiGo’.</p>
     <SubTitle>Our People and our Values</SubTitle> 
     <p>We understand that a highly engaged and motivated workforce leads to higher levels of customer service. Our core strength lies in our highly skilled, motivated, and engaged employees, who enable us to deliver the courteous and hassle-free service to the 300,000 passengers flying with us each day. The teamwork displayed by our employees is tangible across our stations and departments. We are proud to have ifly, one of the largest aviation training academies in the world, where the IndiGo spirit is created for over 2,000 people a day.
Over the years a strong IndiGo culture has been build and nurtured. This is centered around our five core values - Always safe, Passionately consistent, Service from the heart, Humility with pride, and Power of We - which our staff exhibit in their daily work. With our Made in IndiGo program, we give room to our home-grown 6E employees to flourish.
Sustainability commitment
At IndiGo, we strive to deliver our customer promise in the most sustainable way possible. With our investments in the new generation aircraft, we have been able to reduce CO2 footprint by around 19% in financial year 2024 as compared to 7 years ago. Today, around 78% of our fleet is new generation and due to that, we are one of the lowest CO2 emitting airlines in the world. Apart from this, sustainability is integrated at the operational level and we are making a series of investments towards more responsible flying including single engine taxiing, introduction of electric ground vehicles, electronic flight bags and water conservation techniques on-board.
Reaching out to people not only with planes but also with our hearts
Being a responsible airline, we believe that Corporate Social Responsibility (CSR) is an important aspect of our growth strategy. Our CSR arm, IndiGoReach, contributes towards the goal of sustainable development for communities. It focuses on four themes: children and education, women empowerment, environment, and heritage. With different programs being implemented across 18 Indian states we have been able to reach over 100,000 beneficiaries.
Recognitions
As India’s most preferred airline, we were recognized by several reputed organizations from around the world:
</p>

<li>We were awarded with the CAPA Environmental Sustainability Awards for 2023 in the category Asia Environmental Sustainability Airline of the Year.</li>
<li>We were awarded with “World’s Youngest Aircraft Fleet” in the 100+ aircraft category by ch-aviation.</li>
<li>We were ranked as the 5th most punctual mega airline in the world by the Official Aviation Guide (‘OAG’).</li>
<li>We were awarded as the “Best Low-Cost Airline in India & South Asia” by SkyTrax in 2023, for the 13th time in a row.</li>
      
     <SubTitle>Customer Promises</SubTitle>
     <p>employees is tangible across our stations and departments. We are proud to have ifly, one of the largest aviation training academies in the world, where the IndiGo spirit is created for over 2,000 people a day.</p> 
    <p>Over the years a strong IndiGo culture has been build and nurtured. This is centered around our five core values - Always safe, Passionately consistent, Service from the heart, Humility with pride, and Power of We - which our staff exhibit in their daily work. With our Made in IndiGo program, we give room to our home-grown 6E employees to flourish.</p>
     <SubTitle>Sustainability commitment</SubTitle>
     <p>At IndiGo, we strive to deliver our customer promise in the most sustainable way possible. With our investments in the new generation aircraft, we have been able to reduce CO2 footprint by around 19% in financial year 2024 as compared to 7 years ago. Today, around 78% of our fleet is new generation and due to that, we are one of the lowest CO2 emitting airlines in the world. Apart from this, sustainability is integrated at the operational level and we are making a series of investments towards more responsible flying including single engine taxiing, introduction of electric ground vehicles, electronic flight bags and water conservation techniques on-board.</p> 
    <SubTitle>Reaching out to people not only with planes but also with our hearts</SubTitle>
     <p>Being a responsible airline, we believe that Corporate Social Responsibility (CSR) is an important aspect of our growth strategy. Our CSR arm, IndiGoReach, contributes towards the goal of sustainable development for communities. It focuses on four themes: children and education, women empowerment, environment, and heritage. With different programs being implemented across 18 Indian states we have been able to reach over 100,000 beneficiaries.</p> 

      </MainSection>
     <BottomAbout />
     <Footer />
    
    </>
  );
}

export default About;
