import React from 'react';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const CenteredSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-image: url('.//back.jpg'); /* Specify the path to your image */
  background-size: cover; /* Cover the entire section */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  text-align: center;
`;

const SectionDivider = styled.div`
  width: 20%; /* Set the width of the divider */
  height: 2px; /* Set the height of the divider */
  background-color: #000; /* Color of the divider */
  margin: 10px 0; /* Add margin to top and bottom */
`;

const CardContainer = styled(Grid)`
  margin-top: 20px;
  width: 100%;
`;

const StyledCard = styled(Card)`
  max-width: 345px;
  margin: 20px;
  height: 400px; /* Set a fixed height for the cards */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space out the content within the card */
`;

const StyledCardMedia = styled(CardMedia)`
  width: 100px; /* Set a specific width */
  height: 100px; /* Set a specific height */
  border-radius: 50%; /* Make the image circular */
  object-fit: cover; /* Ensure the image covers the entire area */
  margin: 0 auto; /* Center the image horizontally */
  margin-top: 20px; /* Add some margin to the top */
`;

function CenteredSection({ cardsData }) {
    return (
        <CenteredSectionWrapper>
            <h1>Industry Intelligence</h1>
            <SectionDivider />
            <p>Our research and resources help members stay ahead of the curve and better serve their passengers, customers, and communities.</p>
            <CardContainer container justifyContent="center">
                {cardsData.map((card, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4}>
                        <StyledCard>
                            <StyledCardMedia
                                component="img"
                                alt={card.title}
                                image={card.image}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    {card.title}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {card.description}
                                </Typography>
                            </CardContent>
                            <Button size="small" color="primary">Learn More</Button>
                        </StyledCard>
                    </Grid>
                ))}
            </CardContainer>
        </CenteredSectionWrapper>
    );
}

export default CenteredSection;
