import React from 'react';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const AcinaSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  text-align: center;
`;

const BlueDivider = styled.div`
  width: 20%;
  height: 2px;
  background-color: #000;
  margin: 10px 0;
`;

const Column = styled.div`
  padding: 20px;
  text-align: left;
  margin-bottom: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
`;

const BannerImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

function AciSection() {
  return (
    <AcinaSection>
      <Typography variant="h4" style={{ color: 'black' }}>
        The Latest from ACI-NA
      </Typography>
      <BlueDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <Column>
            <Typography variant="h6">Reaching the Next Generation Resiliency Lessons from 9/11’s Aviation Heroes</Typography>
            <Typography variant="caption" display="block" gutterBottom>Published on: May 25, 2024</Typography>
            <Typography variant="body1">
              By Tom Murphy, Human Resiliency Institute at Fordham University Today’s young employees at airports and students studying aviation will be tomorrow’s leaders. Undoubtedly, they will face challenges. The Human Resiliency Institute at Fordham University, which also offers Edge4Vets, is partnering with Airports Council International-North America (ACI-NA) to build upon progress made in 2023 with the “Reclaiming the Sky Resiliency Project” to give our industry’s young professionals (age forty and under) an opportunity to learn resiliency lessons from the aviation heroes of September 11, 2001 and develop tools to meet those challenges. The program, which was also introduced this spring to include […]
            </Typography>
          </Column>
          <Column>
            <Typography variant="h6">Our 10 Year Journey to a More Sustainable Industry</Typography>
            <Typography variant="caption" display="block" gutterBottom>Published on: April 22, 2024</Typography>
            <Typography variant="body1">
              Journeys and airports go hand-in-hand. As we mark another Earth Day, today is a good opportunity to reflect on our industry’s own journey of creating and fostering a more sustainable industry. Airports are in the business of being good neighbors to the local communities they serve, and our commitment to thinking about the future has never been stronger. The journey toward our industry’s commitment to achieve net zero carbon emissions by 2050 is only possible through collaboration and awareness. North American airports joined the global conversation in 2014 when the industry joined as a partner in the Airport Carbon […]
            </Typography>
          </Column>
          <Column>
            <Typography variant="h6">Airports Soar with New Technology for Safety and Operational Excellence</Typography>
            <Typography variant="caption" display="block" gutterBottom>Published on: March 18, 2024</Typography>
            <Typography variant="body1">
              Michael Bettua CEO and Co-founder, Volan Technology One thing I’m hearing a lot about from airport operators is the stress around ensuring compliance with the FAA’s Safety Management System rule that went into effect this year. The new rule requires airports to do a better job detecting incidents, analyzing what happened, capturing data, and reporting it all to stay compliant. More than 250 major US airports are affected, and they’re feeling the burden – especially when it comes to monitoring the outdoor areas like runways, taxiways and ramps. That’s where a lot of the riskier incidents can occur with aircraft, vehicles, […]
            </Typography>
          </Column>
          <Column>
            <Typography variant="h6">Leveraging digitalization for a top-down approach to improving airport sustainability</Typography>
            <Typography variant="caption" display="block" gutterBottom>Published on: February 10, 2024</Typography>
            <Typography variant="body1">
              By John Kasuda Across North America, airports – as gateways to the world – stand at the forefront of decarbonization commitments and strategic initiatives. Today, 75 North American airports have achieved accreditation from the Airport Carbon Accreditation program, which independently assesses and recognizes airports’ efforts to manage and reduce their carbon emissions. Still, there is more work to be done to achieve net-zero carbon emissions goals by 2050. Embracing digitalization and the emerging technologies it enables promises a path forward to integrate sustainability into every facet of airport operations, from electrifying operations and reducing the carbon footprint to enhancing the […]
            </Typography>
          </Column>
        </Grid>
        <Grid item xs={12} md={2}>
          <BannerImage src=".//add1.jpg" alt="Banner" />
          <BannerImage src=".//add.jpg" alt="Banner" />
          <BannerImage src=".//add1.jpg" alt="Banner" />
        </Grid>
      </Grid>
    </AcinaSection>
  );
}

export default AciSection;
