import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';

export default function MobileNav() {
  const handleMenuClick = () => {
    // Implement menu click logic here
    console.log("Menu clicked");
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ background: '#1B065E' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="home"
            sx={{ mr: 2 }}
            onClick={handleMenuClick}
            component={Link}
            to="/"
          >
            <HomeIcon />
          </IconButton>
          <img
            src=".//logo1.png" 
            alt="indigo"
            style={{ marginRight: 'auto', height: '50px' }}
          />
          <Button color="inherit" component={Link} to="/register">Registration</Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
