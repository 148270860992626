import React, { useState } from 'react';
import { Container, TextField, Button, Typography, MenuItem, Paper, Grid, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { firestore, collection, addDoc, serverTimestamp, deleteDoc, doc } from './FirebaseConfig';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const jobPosts = [
  'Ground Staff Helper',
  'Ground Staff Laggege Checker',
  'Ground Staff Passport Checker',
  'Ground Staff Supervisor',
  'Ground Staff Manager',
  'Other'
];

const educations = [
  '8 Pass',
  '10th',
  '12th Diploma',
  'Graduate',
  'Bachelor\'s Degree',
  'Master\'s Degree',
  'Ph.D.',
  'Diploma',
  'Other'
];

const statesWithCities = {
  'Andaman and Nicobar Islands': ['Port Blair'],
  'Andhra Pradesh': ['Kadapa', 'Vijayawada', 'Rajahmundry', 'Tirupati', 'Visakhapatnam'],
  'Assam': ['Guwahati', 'Dibrugarh', 'Jorhat', 'Silchar'],
  'Bihar': ['Patna', 'Gaya'],
  'Chhattisgarh': ['Bilaspur', 'Raipur'],
  'Delhi': ['New Delhi'],
  'Gujarat': ['Ahmedabad', 'Surat', 'Vadodara', 'Bhuj', 'Porbandar', 'Rajkot'],
  'Haryana': ['Hisar'],
  'Himachal Pradesh': ['Kangra', 'Shimla'],
  'Jammu and Kashmir': ['Jammu', 'Srinagar'],
  'Jharkhand': ['Bokaro', 'Ranchi'],
  'Karnataka': ['Bangalore', 'Mangalore', 'Bellary', 'Belgaum', 'Mysore', 'Kalaburagi', 'Hubli'],
  'Kerala': ['Calicut', 'Kannur', 'Kochi', 'Thiruvananthapuram', 'Kollam'],
  'Chandigarh': ['Chandigarh'],
  'Madhya Pradesh': ['Indore', 'Jabalpur', 'Bhopal', 'Gwalior'],
  'Maharashtra': ['Mumbai', 'Nagpur', 'Pune', 'Nashik', 'Nanded', 'Kolhapur', 'Aurangabad', 'Jalgaon', 'Akola'],
  'Manipur': ['Imphal'],
  'Meghalaya': ['Shillong'],
  'Nagaland': ['Dimapur'],
  'Odisha': ['Bhubaneswar', 'Jeypore'],
  'Puducherry': ['Pondicherry'],
  'Punjab': ['Amritsar', 'Pathankot', 'Ludhiana', 'Patiala', 'Jalandhar'],
  'Rajasthan': ['Jaipur', 'Bikaner', 'Kota', 'Ajmer', 'Jodhpur', 'Udaipur'],
  'Tamil Nadu': ['Salem', 'Chennai', 'Coimbatore', 'Madurai', 'Tiruchirappalli'],
  'Telangana': ['Hyderabad'],
  'Tripura': ['Agartala'],
  'Uttar Pradesh': ['Kanpur', 'Ghaziabad', 'Agra', 'Gorakhpur', 'Prayagraj', 'Lucknow', 'Varanasi'],
  'Uttarakhand': ['Dehradun', 'Pantnagar'],
  'West Bengal': ['Kolkata', 'Siliguri']
};


const Registration = () => {
  const [submitting, setSubmitting] = useState(false);
  const [token, setToken] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    name: Yup.string().required('First Name is required').min(3, 'Name must be at least 3 characters'),
    lastName: Yup.string().required('Last Name is required').min(3, 'Last Name must be at least 3 characters'),
    dob: Yup.date()
      .required('Date of Birth is required')
      .max(dayjs().subtract(18, 'years').toDate(), 'You must be at least 18 years old'),
    jobPost: Yup.string().required('Job Post is required'),
    education: Yup.string().required('Education is required'),
    mobileNo: Yup.string()
      .required('Mobile Number is required')
      .matches(/^[6-9]\d{9}$/, 'Mobile Number is not valid'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    address: Yup.string().required('Present Address is required').min(10, 'Address must be at least 10 characters'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    panNo: Yup.string().required('PAN Number is required').matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, 'Invalid PAN Number'),
    uidNo: Yup.string().required('UID Number is required').matches(/^\d{12}$/, 'Invalid UID Number'),
    passportNo: Yup.string().required('Passport Number is required').matches(/^[A-Z]{1}[0-9]{7}$/, 'Invalid Passport Number'),
    aviationCert: Yup.string().required('Aviation Certificate is required')
  });

  const generateToken = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  }

  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);

      // Perform form validation
      const isValid = await validationSchema.isValid(values);

      if (!isValid) {
        setSubmitting(false);
        return;
      }

      // Generate a random token
      const newToken = generateToken();

      // Get current timestamp
      const timestamp = serverTimestamp();

      // Store form data, timestamp, token, and status in Firestore
      const docRef = await addDoc(collection(firestore, 'loanApplications'), {
        ...values,
        timestamp: timestamp,
        token: newToken,
        status: 'Pending'
      });

      setToken(newToken);
      setOpen(true);

      // Set token expiry time (8 hours)
      setTimeout(async () => {
        await deleteDoc(doc(firestore, 'loanApplications', docRef.id));
        console.log('Token deleted after 8 hours:', docRef.id);
        setToken(null);
      }, 8 * 60 * 60 * 1000); // 8 hours in milliseconds
    } catch (error) {
      console.error('Error adding form data:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStateChange = (event, setFieldValue) => {
    const state = event.target.value;
    setSelectedState(state);
    setFieldValue('state', state);
    setFieldValue('city', ''); // Reset city when state changes
  };

  const handleCloseWithConfirmation = () => {
    if (window.confirm('Are you sure you noted the token number?')) {
      setOpen(false);
      navigate('/');
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        <Paper elevation={3} style={{ padding: '20px', marginTop: '50px', marginBottom: '50px' }}>
          <Typography variant="h4" align="center" gutterBottom>Registration Form</Typography>
          <Formik
            initialValues={{
              name: '',
              lastName: '',
              dob: '',
              jobPost: '',
              education: '',
              mobileNo: '',
              email: '',
              address: '',
              state: '',
              city: '',
              panNo: '',
              uidNo: '',
              passportNo: '',
              aviationCert: ''
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ errors, touched, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="name"
                      label="First Name"
                      fullWidth
                      error={touched.name && !!errors.name}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="lastName"
                      label="Last Name"
                      fullWidth
                      error={touched.lastName && !!errors.lastName}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="dob"
                      label="Date of Birth"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      error={touched.dob && !!errors.dob}
                      helperText={touched.dob && errors.dob}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="jobPost"
                      label="Job Post"
                      select
                      fullWidth
                      error={touched.jobPost && !!errors.jobPost}
                      helperText={touched.jobPost && errors.jobPost}
                    >
                      {jobPosts.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="education"
                      label="Education"
                      select
                      fullWidth
                      error={touched.education && !!errors.education}
                      helperText={touched.education && errors.education}
                    >
                      {educations.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="mobileNo"
                      label="Mobile Number"
                      fullWidth
                      error={touched.mobileNo && !!errors.mobileNo}
                      helperText={touched.mobileNo && errors.mobileNo}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="email"
                      label="Email"
                      fullWidth
                      error={touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="address"
                      label="Present Address"
                      fullWidth
                      error={touched.address && !!errors.address}
                      helperText={touched.address && errors.address}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="state"
                      label="State"
                      select
                      fullWidth
                      onChange={(event) => handleStateChange(event, setFieldValue)}
                      error={touched.state && !!errors.state}
                      helperText={touched.state && errors.state}
                    >
                      {Object.keys(statesWithCities).map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="city"
                      label="City"
                      select
                      fullWidth
                      error={touched.city && !!errors.city}
                      helperText={touched.city && errors.city}
                      disabled={!selectedState}
                    >
                      {selectedState && statesWithCities[selectedState].map((city) => (
                        <MenuItem key={city} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="panNo"
                      label="PAN Number"
                      fullWidth
                      error={touched.panNo && !!errors.panNo}
                      helperText={touched.panNo && errors.panNo}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="uidNo"
                      label="UID Number"
                      fullWidth
                      error={touched.uidNo && !!errors.uidNo}
                      helperText={touched.uidNo && errors.uidNo}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="passportNo"
                      label="Passport Number"
                      fullWidth
                      error={touched.passportNo && !!errors.passportNo}
                      helperText={touched.passportNo && errors.passportNo}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="aviationCert"
                      label="Aviation Certificate"
                      fullWidth
                      error={touched.aviationCert && !!errors.aviationCert}
                      helperText={touched.aviationCert && errors.aviationCert}
                    />
                  </Grid>
                  <Grid item xs={12} textAlign="center">
                    <Button type="submit" variant="contained" color="primary" fullWidth disabled={submitting}>
                      {submitting ? 'Please Wait...' : 'Submit'}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle style={{ color: 'green' }}>Thanks for your registration</DialogTitle>
            <DialogContent>
              <Typography variant="body1" style={{ marginBottom: '20px' }}>
                Please note this token number carefully. This token is valid for 8 hours.
              </Typography>
              <Typography variant="h6" style={{ marginBottom: '10px' }}>
                Token Number: {token}
              </Typography>
              <Typography variant="body1">
                This token number is your test key.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseWithConfirmation} color="primary">Close</Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default Registration;
