// Navbar.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import MobileNav from './MobileNav';

const CustomNavbar = () => {
    const [isMobileView, setIsMobileView] = useState(false);

    const checkMobileView = () => {
        setIsMobileView(window.innerWidth <= 768); // Adjust the threshold as needed
    };

    useEffect(() => {
        checkMobileView(); // Check initial viewport size
        window.addEventListener('resize', checkMobileView);
        return () => window.removeEventListener('resize', checkMobileView);
    }, []);

    return (
        <div>
            <section className="Header">
                <div className="container">
                    <div className="logo"></div>
                    <nav className="menu"></nav>
                </div>
            </section>
            <section className="Navbar">
                <div className="container">
                    <nav className="menu">
                        <ul className="Menu-links">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/question">Q&A Test</Link></li>
                            <li><Link to="/trackApp">Search Result</Link></li>
                            
                            <li><a href="#">Programs</a></li>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/register">Registration</Link></li>
                        </ul>
                    </nav>
                </div>
            </section>
            {isMobileView && <MobileNav />}
        </div>
    );
};

export default CustomNavbar;
