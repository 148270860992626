import React from 'react';
import styled from '@emotion/styled';
import NewSection from './NewSection';
import CenteredSection from './CenteredSection';
import BlueSection from './BlueSection';
import AciSection from './AciSection';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 60vh; /* Adjusted height to 60% of the viewport height */
  overflow: hidden;
`;

const GradientText = styled.span`
  background: linear-gradient(90deg, #007bff, #00d4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px; /* Font size increased */
  font-weight: bold; /* Font weight set to bold */
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Match the height of the image */
  background-color: rgba(0, 0, 0, 0.3); /* Black transparent overlay */
  display: flex;
  justify-content: flex-start; /* Align content to the left */
  align-items: center;
  padding-left: 20px; /* Add padding to the left side */
`;

const Content = styled.div`
  color: #fff;
  text-align: left; /* Align text to the left */
  font-weight: bold; /* Make all text bold */
`;

const NormalText = styled.span`
  font-weight: normal; /* Make this text normal */
`;

const SmallText = styled.p`
  font-size: 0.5em; /* Make this text smaller */
  font-weight: normal; /* Normal weight for this text */
`;

const Divider = styled.span`
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: #fdd;
  margin: 10px 0;
`;

const StyledButton = styled.a`
  color: #fff;
  font-size: 0.5em;
  background: linear-gradient(90deg, #007bff, #00d4ff);
  padding: 10px 20px;
  border-radius: 50px;
  text-decoration: none;
  transition: background 0.3s ease;

  &:hover {
    background: linear-gradient(90deg, #00d4ff, #007bff);
  }
`;

const contentItems = [
  { title: 'Policy Issue', description: 'Helping airports operate more safely and efficiently.', details: 'ACI-NA advocates policies and provides services that strengthen the ability of commercial airports to serve their passengers, customers and communities. ACI-NA professional staff in the government affairs, legal, environment, safety, security, operations and technical departments, works to ensure that legislation and regulations enacted in Washington, D.C., and Ottawa, help airports operate more safely and efficiently.' },
  { title: 'Airports Infrastructure Funding', description: 'America’s airports face unprecedented challenges meeting their $115 billion in infrastructure needs.', details: 'America’s airports are powerful engines for economic opportunity in local communities, generating more than $1.4 trillion in annual economic activity and supporting nearly 11.5 million jobs. While passenger traffic through airport facilities continues to grow a record pace, our outdated aviation infrastructure cannot keep pace with this overwhelming demand.' },
  { title: 'Airline Competition', description: 'Through competition, we can improve air travel for everyone, even the airlines.', details: 'As the connection point between passengers and the sky, airports serve a vital role in fostering competition and providing passengers with the price and service options they demand. Through that important work, airports emerge as powerful engines of economic growth for local communities.' },
  { title: 'Security', description: 'The safety and security of the traveling public is an airport’s top priority.', details: 'Airports operate in an uncertain landscape filled with evolving requirements to address concerns about ever-changing global aviation security threats. Maintaining the safety and security of the traveling public is a responsibility that is shared by airports, airlines, airport partners, federal government agencies, law enforcement, and the traveling public.' },
  { title: 'Airport Operations', description: 'Airports are highly regulated enterprises, and those regulations can place unfair or unnecessary burdens on airports.', details: 'Airports are responsible stewards of the environment, including noise concerns and deicing processes. An airport’s ability to maintain safety, security, and environmental challenges promotes efficient airport operations and reduced flight delays.' },
  { title: 'Passenger Experience', description: 'We remain fully committed to the efficient movement of passengers through the airport.', details: 'Airports want the same thing their passengers want: a hassle-free travel experience with excellent price and service competition. In addition to modernizing our aging infrastructure with more comfortable and hassle-free terminals, we continue to work with our security agency partners to ensure passengers aren’t standing in long lines to get through our airports.' },
];


const cardsData = [
  {
    title: 'Industry Benchmarking Studies',
    description: 'We’ve compiled data on airport traffic, amenities and services, as well as information on key issues and policies.',
    image: './/card3.png',
  },
  {
    title: 'Airport Infrastructure Needs Study',
    description: 'Our Airport Infrastructure Needs Survey estimates capital development costs for the airports that comprise the United States national airport system, defined by the Federal Aviation Administration (FAA).',
    image: './/card1.png',
  },
  {
    title: 'Economic Impact Study',
    description: 'The study, which summarizes the economic benefits that the 493 commercial airports in the U.S. make to the national economy, found the total economic output now exceeds $1.4 trillion, supporting more than 11.5 million jobs with a payroll of more than $428 billion.',
    image: './/card2.png',
  },
];

const ACISection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: white;
  text-align: center;
`;

const SectionDivider = styled.div`
  width: 20%; /* Set the width of the divider */
  height: 2px; /* Set the height of the divider */
  background-color: black; /* Color of the divider */
  margin: 10px 0; /* Add margin to top and bottom */
`;

function MainPage() {

  const navigate = useNavigate(); // Initialize useNavigate

  return (
    <>
      <Root>
        <img src="/img1.png" alt="Background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        <Overlay>
          <Content>
            <h1 className="acni-logo">
              <NormalText>THE</NormalText> <span className="logo-part-1">SPIRIT</span> <NormalText>OF</NormalText> <span className="logo-part-2">AIRPORTS</span><sup>®</sup>
              <SmallText> IndiGo is India’s largest and most preferred passenger airline</SmallText>
              <Divider />
              <p><StyledButton onClick={() => navigate('/register')} className="btn logged-out">Member Login</StyledButton></p>
            </h1>
          </Content>
        </Overlay>
      </Root>
      <NewSection contentItems={contentItems} />
      <CenteredSection cardsData={cardsData} />
      <BlueSection />
      <AciSection />
      <Footer />
    </>
  );
}

export default MainPage;
