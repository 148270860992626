import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const GradientText = styled.span`
  background: linear-gradient(90deg, #007bff, #00d4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
  font-weight: bold;
`;

const VerticalDivider = styled(Box)`
  width: 1px;
  background-color: #ddd;
  height: 100%;
  margin: 0 20px;
`;

const ContentBox = styled(ListItem)`
  background-color: #ffffff;
  border-radius: 20px;
  margin: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
`;

const NewSectionContainer = styled.div`
  padding: 20px;
  margin: 10px 0;
`;

const Divider = styled(Box)`
  width: 50%;
  height: 2px;
  background-color: #000;
  margin: 20px 0;
`;

function NewSection({ contentItems }) {
    const [selectedContent, setSelectedContent] = useState(contentItems[0]);

    const handleItemClick = (item) => {
        setSelectedContent(item);
    };

    useEffect(() => {
        setSelectedContent(contentItems[0]);
    }, [contentItems]);

    return (
        <NewSectionContainer>
            <Grid container spacing={0} style={{ marginTop: '0px' }}>
                <Grid item xs={12} md={4}>
                    <Box>
                        <List>
                            {contentItems.map((item, index) => (
                                <ContentBox
                                    button
                                    key={index}
                                    onClick={() => handleItemClick(item)}
                                    style={{ backgroundColor: selectedContent.title === item.title ? '#fddcde' : 'transparent' }}
                                >
                                    <ListItemText primary={<GradientText>{item.title}</GradientText>} />
                                </ContentBox>
                            ))}
                        </List>
                    </Box>
                </Grid>
                <Grid item>
                    <VerticalDivider />
                </Grid>
                <Grid item xs={12} md={7}>
                    {selectedContent ? (
                        <>
                            <Typography variant="h5" gutterBottom>{selectedContent.description}</Typography>
                            <Divider />
                            <Typography variant="body1">{selectedContent.details}</Typography>
                        </>
                    ) : (
                        <Typography variant="h6" color="textSecondary">Select an item to see the details</Typography>
                    )}
                </Grid>
            </Grid>
        </NewSectionContainer>
    );
}

export default NewSection;
