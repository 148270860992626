// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';

import MainPage from './MainPage';
import About from './About';
import AuthenticatedAdminPanel from './AuthenticatedAdminPanel';
import Registration from './Registration';
import TrackApplication from './TrackApplication';
import Questions from './Questions';
import AdminLogin from './AdminLogin';

function App() {
    return (
        <Router>
            <div>
                <Navbar />
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/register" element={<Registration />} />
                    <Route path="/about" element={<About />} /> 
                    <Route path="/trackApp" element={<TrackApplication />} />
                    <Route path="/question" element={<Questions />} />
                    <Route path="/admin" element={<AdminLogin />} />
                    <Route path="/dashboard" element={<AuthenticatedAdminPanel />} />
                    
                </Routes>
            </div>
        </Router>
    );
}

export default App;
