import React from 'react';
import { Container, Typography, Grid, Box, Avatar } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VerifiedIcon from '@mui/icons-material/Verified';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FastForwardIcon from '@mui/icons-material/FastForward';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PublicIcon from '@mui/icons-material/Public';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import PeopleIcon from '@mui/icons-material/People';
import LuggageIcon from '@mui/icons-material/Luggage';
import ChatIcon from '@mui/icons-material/Chat';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import ShareIcon from '@mui/icons-material/Share';
import FlightIcon from '@mui/icons-material/Flight';
import AltRouteIcon from '@mui/icons-material/AltRoute';

const BottomAbout = () => {
  return (
    <Container maxWidth={false} sx={{ backgroundColor: '#1f1f7a', color: 'white', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <Typography variant="h2" sx={{ fontWeight: 'bold', marginBottom: '10px', textAlign: 'center' }}>IndiGo</Typography>
      <Typography variant="h6" sx={{ marginBottom: '40px', textAlign: 'center' }}>Giving wings to the nation since 2006</Typography>

      <Section title="On time performance" icon={<AccessTimeIcon sx={{ fontSize: 40 }} />}>
        <Row sx={{textAlign: 'center' , marginLeft: '50px' }}>
          <Item icon={<AccessTimeIcon />} text="Most punctual airline in India for 2023" />
          <Item icon={<VerifiedIcon />} text="Highest technical dispatch reliability of 99.92%" />
        </Row>
        <Row sx={{textAlign: 'center'}}>
          <Item icon={<FlightTakeoffIcon />} text="Amongst the top 10 global airlines for OTP" />
          <Item icon={<FastForwardIcon />} text="Faster deboarding with Third Ramp saves up to 5 min" />
        </Row>
      </Section>

      <Section title="Affordable fares" icon={<AttachMoneyIcon sx={{ fontSize: 40 }} />}>
        <Row sx={{textAlign: 'center' , marginLeft: '50px' }}>
          <Item icon={<AttachMoneyIcon />} text="Reduced booking fee on direct channels" />
          <Item icon={<LocalOfferIcon />} text="Periodic sales across network" />
        </Row>
        <Row sx={{textAlign: 'center'  }}>
          <Item icon={<PublicIcon />} text="Making air travel accessible for millions of Indians" />
          <Item icon={<ThumbUpIcon />} text="Best Low-Cost Airline in India and South Asia" />
        </Row>
      </Section>

      <Section title="Courteous and hassle-free service" icon={<RestaurantIcon sx={{ fontSize: 40 }} />}>
        <Row sx={{textAlign: 'center' , marginLeft: '50px' }}>
          <Item icon={<RestaurantIcon />} text="6E Eats: Revamped and quicker catering service on board" />
          <Item icon={<PeopleIcon />} text="One of the highest customer NPS in India" />
        </Row>
        <Row sx={{textAlign: 'center' }}>
          <Item icon={<LuggageIcon />} text="Lowest level of baggage mishandling" />
          <Item icon={<ChatIcon />} text="AI-enabled chatbot for an elevated customer experience" />
        </Row>
      </Section>

      <Section title="Unparalleled network" icon={<AirplanemodeActiveIcon sx={{ fontSize: 40 }} />}>
        <Row sx={{textAlign: 'center' , marginLeft: '50px' }}>
          <Item icon={<AirplanemodeActiveIcon />} text="85 domestic and 32 international destinations" />
          <Item icon={<ShareIcon />} text="8 airline codeshare partners" />
        </Row>
        <Row sx={{ textAlign: 'center' }}>
          <Item icon={<FlightIcon />} text="2,000+ flights a day" />
          <Item icon={<AltRouteIcon />} text="Over 537 routes within India and international" />
        </Row>
      </Section>

      
    </Container>
  );
};

const Section = ({ title, icon, children }) => {
  return (
    <Box sx={{ marginBottom: '40px', textAlign: 'left' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <Avatar sx={{ width: 60, height: 60, marginRight: '20px', bgcolor: 'white', color: '#1f1f7a' }}>
          {icon}
        </Avatar>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{title}</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {children}
      </Box>
    </Box>
  );
};

const Row = ({ children, sx }) => {
  return (
    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', maxWidth: '800px', ...sx }}>
      {children}
    </Grid>
  );
};

const Item = ({ icon, text }) => {
  return (
    <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'left' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ marginRight: '10px' }}>{icon}</Box>
        <Typography variant="body1">{text}</Typography>
      </Box>
    </Grid>
  );
};

export default BottomAbout;
